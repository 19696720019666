<template>
  <div>
    <sm-editable-list
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="tableHeaders"
      showCreateButton
      showEditButton
      showDeleteButton
      @create="onCreate('SupportReqTemplateCreate')"
      @edit="(id) => onEdit('SupportReqTemplateEdit', id)"
    />
  </div>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SupportReqTemplates',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SupportReqTemplates',
      tableCaption: 'Шаблоны заявок',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Шаблоны заявок',
        },
      ],
      tableHeaders: [
        { text: 'Клиент', alias: 'customerName', order: 'customerName' },
        { text: 'Аккаунт', alias: 'accountName', order: 'accountName' },
        {
          text: 'Наименование заявки',
          alias: 'applicationName',
          order: 'applicationName',
        },
        { text: '', alias: 'actions' },
      ],
    };
  },
};
</script>
